import React from 'react'
import Nav from '../components/Nav'
import Project from '../components/Project'
import Letsconnect from '../components/Letsconnect'
import Footer from '../components/Footer'
import tipstaCover from '../assets/tipsta_cover.jpg'
import buzzmeCover from '../assets/buzzme_cover.jpg'
import collampusCover from '../assets/collampus_cover.jpg';
import goingGlobalCover from '../assets/goingglobal_cover.jpg';

function Projects() {
  const projectData = [
    {
      title: 'Tipsta',
      subTitle: 'Discover & Share Experiences',
      img: tipstaCover
    },
    {
      title: 'Collampus',
      subTitle: 'Connect, Collaborate, Innovate',
      img:  collampusCover
    },
    {
      title: 'Going Global Partnership',
      subTitle: 'International Workshops, Local Impact',
      img:   goingGlobalCover
    },
    {
      title: 'Buzz.Me',
      subTitle: 'Local Events at Your Fingertips',
      img: buzzmeCover
    }
  ]
  return (
    <div className="flex h-screen w-full">
    {/* Nav should take 20% of the width and remain fixed */}
    <div className="md:w-[20%] ">
      <Nav />
    </div>
    
    {/* The Hero content should take up the remaining 80% and be scrollable */}
    <div className="md:w-[80%]  md:px-16 md:pt-32 py-20 p-5">
    <div className=' text-white animate-slideUp text-2xl md:text-3xl font-medium text-opacity-90 mb-7 md:mb-10'>
      Our Remarkable Projects
    </div>
    <div className=' flex flex-wrap gap-8'>
      {
        projectData.map((data,index)=>(
          <Project project={{image:data.img,name:data.title,description:data.subTitle,link:'/'}}/>
        ))
      }
    </div>
     <Letsconnect/>
     <div className=' pb-4'>

     <Footer/>
     </div>
    </div>
  </div>
  )
}

export default Projects
