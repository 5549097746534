import { motion } from 'framer-motion';
import React from 'react';

function Work() {
  const Services = [
    {
      title: "● Mobile Apps",
    },
    {
      title: "Web Development",
    },
    {
      title: "AI Solutions",
    },
    {
      title: "Cloud Integration",
    },
  ];

  return (
    <div className='overflow-hidden w-[87vw] md:w-full my-12 md:my-16  gradient-overlay'>
    <div className='  flex gap-2  '>
      <div
        className='flex items-center -z-10 text-3xl md:text-4xl text-white text-opacity-[0.5] animate-loop-scroll   font-extrabold'
      >
        {Services.map((service, index) => (
          <div key={index} className='flex items-center whitespace-nowrap'>
            <span className={`${index===0?"mx-0 hidden":"mx-2"}`}>●</span>
            {/* Add a dot after every service except the last one */}
           
            <span>{service.title}</span>
          </div>
        ))}
      </div>
      <div
        className='flex items-center -z-10  text-3xl md:text-4xl text-white text-opacity-50 animate-loop-scroll  font-extrabold'
      >
        {Services.map((service, index) => (
          <div key={index} className='flex items-center whitespace-nowrap'>
            <span className={`${index===0?"mx-0 hidden":"mx-2"}`}>●</span>
            {/* Add a dot after every service except the last one */}
           
            <span>{service.title}</span>
          </div>
        ))}
      </div>
    </div></div>
  );
}

export default Work;
