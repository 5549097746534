import React from "react";
import { motion } from "framer-motion";

function StackMain({ stack }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} // This makes the animation trigger once as the component comes into view
      className="bg-white bg-opacity-10 animate-slideUp w-full flex gap-4 rounded-lg p-6 px-8"
    >
      <div className="flex flex-col gap-5">
        <div className="flex gap-5 items-center">
          <img src={stack.image} alt="stackIcon" className="h-10" />
          <div className="flex flex-col">
            <div className="text-lg text-white text-opacity-80">{stack.name}</div>
            <div className="text-sm text-opacity-50 font-thin text-white">
              {stack.subdescription}
            </div>
          </div>
        </div>

        <div className="flex gap-10 items-start ml-4">
          <div className="border-l-[1px] border-dotted border-white h-full opacity-50"></div>
          <div className="font-extralight md:w-[70%] w-full text-white text-opacity-50">
            {stack.description}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default StackMain;
