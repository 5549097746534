import React from 'react'
import Hero from '../components/Hero'
import Nav from '../components/Nav'

function DashBoard() {
  return (
    <div className="flex h-screen w-full">
    {/* Nav should take 20% of the width and remain fixed */}
    <div className="md:w-[20%] ">
      <Nav />
    </div>
    
    {/* The Hero content should take up the remaining 80% and be scrollable */}
    <div className="md:w-[80%]  md:px-16 md:pt-32 py-28 p-5">
      <Hero />
    </div>
  </div>
  )
}

export default DashBoard
