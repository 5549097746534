import React, { useState } from "react";
import mailIcon from "../assets/icons-svg/contact.svg";
import callIcon from "../assets/icons-svg/call.svg";
import axios from "axios";
import confetti from "canvas-confetti";
function ContactComp() {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState("$3000-$5000");

  // Budget options
  const budgets = [
    { label: "$3000-$5000", value: "$3000-$5000" },
    { label: "$5K-$10K", value: "$5000-$10000" },
    { label: "$10K-$20K", value: "$10000-$20000" },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      setLoading(true); // Start loading

      await axios.post(
        "https://dihingkriti2024-backend.vercel.app/devbranch/sendemail",
        {
          name: name,
          email: email,
          message: message,
          budget: selectedBudget,
        }
      );

      setSubmitted(true); // Set form as submitted on success
      confetti(); // Celebrate with confetti
      setName(""); // Clear the name field
      setEmail(""); // Clear the email field
      setMessage(""); // Clear the message field
    } catch (err) {
      console.error("Error sending message:", err);
    } finally {
      setLoading(false); // Always stop loading, even in case of an error
    }
  };

  return (
    <div className=" ">
      <div className=" text-white  animate-slideUp text-2xl md:text-3xl font-medium text-opacity-90 mb-10">
        Get in Touch
      </div>
      <div
        className={`  flex flex-col-reverse gap-8 md:gap-0 md:flex-row  w-full`}
      >
        <div className={` flex gap-6 flex-col  md:w-1/2`}>
          <div className="text-white text-base font-normal text-opacity-90">
            Message Us
          </div>
          {submitted ? (
            // Success Message
            <div className="text-center bg-[#4CE6A6] text-white p-10 py-28 rounded-xl animate-fadeInZoom transition-all duration-700">
              <h2 className="text-xl text-black font-medium animate-fadeIn">
                Your message has been received!
              </h2>
              <p className="text-sm text-black font-light animate-fadeInDelay">
                We appreciate your contact and will get back to you as soon as
                possible.
              </p>
            </div>
          ) : (
            // Form Elements (Only visible before submission)
            <form
              onSubmit={handleSubmit}
              className={`${
                loading ? "animate-fadeOut" : "animate-fadeIn"
              } flex flex-wrap gap-4  items-center bg-[#000000] bg-opacity-80 rounded-xl w-full max-w-2xl transition-all duration-500`}
            >
              <div className="flex flex-col gap-4 md:gap-0 w-full md:flex-row md:space-x-4">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                  type="text"
                  placeholder="Name"
                  className={`bg-white placeholder:text-white animate-slideUp md:animate-slideRight focus:outline text-white outline-[#4CE6A6] placeholder:text-opacity-50 text-sm font-light bg-opacity-10 py-4 p-4 rounded-xl w-full md:w-1/2`}
                />
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  type="email"
                  placeholder="Email"
                  className={`bg-white placeholder:text-white animate-slideUp md:animate-slideLeft focus:outline text-white outline-[#4CE6A6] placeholder:text-opacity-50 text-sm font-light bg-opacity-10 py-4 p-4 rounded-xl w-full md:w-1/2`}
                />
              </div>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
                placeholder="Write a message..."
                className={`bg-white placeholder:text-white animate-slideUp focus:outline text-white outline-[#4CE6A6] placeholder:text-opacity-50 text-sm font-light bg-opacity-10 py-4 p-4 rounded-xl w-full h-60`}
              ></textarea>
              <div className="text-white text-base font-normal text-opacity-80">
                Your Budget{" "}
                <span className=" text-xs font-thin">(US Dollars)</span>
              </div>
              <div className="flex gap-2 justify-between w-full">
                {budgets.map((budget) => (
                  <div
                    key={budget.value}
                    onClick={() => setSelectedBudget(budget.value)}
                    className={` p-1 md:p-4 md:py-2 py-2 cursor-pointer text-opacity-80 text-xs md:text-sm flex justify-center items-center rounded-lg w-full
            ${
              selectedBudget === budget.value
                ? "bg-[#4CE6A6]"
                : "bg-white bg-opacity-10 text-white"
            }`}
                  >
                    {budget.label}
                  </div>
                ))}
              </div>
              <button
                disabled={loading}
                className={`bg-[#4CE6A6] notapcolor hover:bg-white hover:text-[#4CE6A6] hover:bg-opacity-20 transition-colors w-full text-black font-normal p-3 rounded-lg`}
              >
                {loading ? "Sending..." : "Send Message"}
              </button>
            </form>
          )}
        </div>
        <div className=" md:w-1/2">
          <div className=" flex flex-col gap-2 md:px-10">
            <div className=" flex flex-col gap-8">
              <div className="text-white text-base font-normal text-opacity-90">
                Contact
              </div>
              <div className=" flex flex-col gap-3">
                <div className="text-white text-opacity-50 flex items-center gap-3 text-sm font-light">
                  <img
                    src={mailIcon}
                    alt="call-icon"
                    className="w-5 h-5 mr-1  inline-block"
                  />
                  hello@devbranch.in
                </div>

                <div className="text-white text-opacity-50 flex items-center gap-3 text-sm font-light">
                  <img
                    src={callIcon}
                    alt="call-icon"
                    className="w-5 h-5 mr-1  inline-block"
                  />
                  +91 7352125020
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactComp;
