import React from "react";
import Project from "./Project";
import mapIcon from "../assets/icons-svg/map-pin-fill.svg";
import arrowRight from "../assets/icons-svg/arrow-right.svg";
import ProjectHome from "./ProjectHome";
import Varient1 from "./Buttons/Varient1";
import Reviews from "./Reviews";
import StackHome from "./StackHome";
import Letsconnect from "./Letsconnect";
import Footer from "./Footer";
import Work from "./Work";
function Hero() {
  return (
    <div className=" bg-opacity-20 ">
      <h1 className="text-4xl text-white font-bold  animate-slideUp">
      Let's Build<span className="text-[#4CE6A6]">.</span>
      </h1>
      <p className=" md:w-[70%] text-white text-opacity-50 pt-5 font-light animate-slideUp">
      At <span className="text-[#4CE6A6] font-semibold">DevBranch</span>, we specialize in custom digital solutions, from App Development and Web Development to Design, DevOps, and AI solutions. Our team is driven by a passion for innovation, always striving to exceed expectations and deliver seamless user experiences.
      </p>
      
      <div className=" my-10 flex items-center justify-between md:justify-normal md:gap-10 animate-slideUp">
        <div className=" text-opacity-50 md:text-sm text-xs text-white flex">
          <img
            src={mapIcon}
            alt="map-icon"
            className="nd:w-5 md:h-5 w-4 h-4 mr-1  inline-block"
          />
          <div className=" whitespace-nowrap">
          Guwahati, India</div>
        </div>
        <hr className="border-t translate-y-[0.5px] border-white hidden md:flex border-dashed border-spacing-2 w-full opacity-50" />
        <Varient1 content={"More About Us"} link={"/about"} />
      </div>
      
      {/* Projects */}
      <h1 className="text-2xl md:text-3xl font-medium text-white my-10 animate-slideUp">
       Recent Projects
      </h1>

      <ProjectHome />
      <div className=" my-10 flex items-center gap-10 animate-slideUp">
        <hr className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full opacity-50" />
        <Varient1 content={"View All Projects"} link={"/projects"} />
      </div>
      {/* Reviews */}
      {/* <h1 className="text-2xl md:text-3xl font-medium text-white my-10 animate-slideUp">
      Services
      </h1> */}
      {/* <Reviews /> */}
      <Work/>
      {/* <Reviews /> */}
      <div className=" my-10 flex items-center gap-10 animate-slideUp">
        <hr className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full opacity-50" />
        {/* <Varient1 content={"View All Projects"} link={"/projects"} /> */}
      </div>
      {/* Stack */}
      <h1 className="text-2xl md:text-3xl font-medium text-white my-10 animate-slideUp">
      Our Tech Toolbox
      </h1>
      <StackHome />
      <div className=" my-10 flex items-center gap-10 animate-slideUp">
        <hr className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full opacity-50" />
        <Varient1 content={"View All Stack"} link={"/stack"} />
      </div>
      <Letsconnect/>
      <div className=" py-5">
      <Footer/></div>
    </div>
  );
}

export default Hero;
