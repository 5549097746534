import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

// Rotation transformation without scaling
const trans = (x, y) =>
  `perspective(800px) rotateX(${x}deg) rotateY(${y}deg)`;

// Calculate rotation based on mouse position
const calc = (x, y) => {
  const BUFFER = 60;
  return [-(y / BUFFER), x / BUFFER];
};
const glowColors = {
  Flutter: "#02569B",  // Flutter's blue color
  "Node.js": "#68A063",     // Node.js green
  React: "#61DBFB",    // React's blue
  // Add more stack colors as needed
};
// Function to create a glow effect around the mouse pointer
const getGlow = (x, y, cardRect,title) => {
  const posX = x - cardRect.left; // Mouse X relative to the card
  const posY = y - cardRect.top;  // Mouse Y relative to the card
  const glowColor = glowColors[title];
  return `radial-gradient(circle at ${posX}px ${posY}px,  ${glowColor}33, transparent 60%)`;
};

const StackComp = ({ stack }) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const [glow, setGlow] = useState("");

  return (
    <animated.div
      className="rounded-xl flex items-center cursor-pointer w-full gap-3 px-10 py-5 glass-card"
      onMouseMove={(e) => {
        const { clientX: x, clientY: y } = e;
        const cardRect = e.currentTarget.getBoundingClientRect(); // Get card dimensions
        set({ xys: calc(x, y) });
        setGlow(getGlow(x, y, cardRect,stack.title)); // Set glow effect based on mouse position
      }}
      onMouseLeave={() => {
        set({ xys: [0, 0] });
        setGlow(""); // Remove glow on mouse leave
      }}
      style={{
        transform: props.xys.to(trans),
        background: glow || "rgba(255, 255, 255, 0.1)", // Glow follows the mouse
        transition: "background 0.1s ease", // Smooth transition for glow
      }}
    >
      <img src={stack.img} className="h-10 w-10 rounded-md" />
      <div className="flex flex-col gap-1">
        <div className="text-white text-opacity-80">{stack.title}</div>
        <div className="font-light text-sm text-white w-[150px] text-opacity-30">
          {stack.subdescription}
        </div>
      </div>
    </animated.div>
  );
};

export default StackComp;
