import React from "react";
import innovationIcon from "../assets/icons-svg/innovation.svg";
import qualityIcon from "../assets/icons-svg/quality.svg";
import collaborationIcon from "../assets/icons-svg/collaboration.svg";
import integrityIcon from "../assets/icons-svg/integrity.svg";
import gear from '../assets/icons-svg/gear.svg';
import development from '../assets/icons-svg/development.svg';
import certified from '../assets/icons-svg/certified.svg';
import grow from '../assets/icons-svg/grow.svg';
import friendly from '../assets/icons-svg/friendly.svg';
import Letsconnect from "./Letsconnect";
import Footer from "./Footer";
import { motion } from "framer-motion";

function AboutComp() {
  const values = [
    {
      title: "Innovation",
      description: "We are driven by a passion for innovation, always striving to exceed expectations and deliver seamless user experiences.",
      iconPath: innovationIcon,
    },
    {
      title: "Quality",
      description: "Delivering excellence in every product and service, with a focus on user experience and functionality",
      iconPath: qualityIcon,
    },
    {
      title: "Collaboration",
      description: "We believe the best solutions come from working together, combining our expertise with your vision.",
      iconPath: collaborationIcon,
    },
    {
      title: "Integrity",
      description: "Building trust through transparency, accountability, and ethical practices in all we do.",
      iconPath: integrityIcon,
    },
  ];

  const process = [
    {
      title: "Understand Your Vision",
      description: "We begin by deeply understanding your goals and vision, ensuring that every aspect of the project aligns with your objectives.",
      iconPath: innovationIcon,
    },
    {
      title: "Collaborative Design and Development",
      description:
        "We work hand-in-hand with you to refine ideas and create detailed roadmaps. Our developers, designers, and engineers work tirelessly to bring your vision to life, with constant feedback loops and revisions.",
      iconPath: collaborationIcon,
    },
    {
      title: "Testing and Refinement",
      description: "Each product is thoroughly tested, with continuous refinement to meet industry standards of performance, security and scalability.",
      iconPath: gear,
    },
    {
      title: "Deployment and Support",
      description:
        "From app launches to web deployment, we manage the entire process, ensuring your product goes live smoothly. We also offer ongoing support for continuous improvements.",
      iconPath: development,
    },
  ];

  const partnerWith = [
    {
      title: "Expertise That Matters:",
      description:
        "We’re all about delivering fresh ideas and innovative tech that make your project truly shine. Our passion for creativity drives us to craft solutions that not only meet your needs but also push the boundaries of what's possible.",
      iconPath: certified,
    },
    {
      title: "Built to Grow:",
      description: "Our solutions are designed to scale with your business, ensuring you have the flexibility to adapt and thrive as your needs evolve.",
      iconPath: grow,
    },
    {
      title: "Startup-Friendly Approach:",
      description:
        "We understand the unique challenges startups face! Our agile development process means we’re quick, flexible, and always ready to adjust to your requirements.",
      iconPath: friendly,
    },
  ];

  return (
    <motion.div
     initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
    className="pb-7">
      <motion.div
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
      className=" text-xl md:text-4xl text-white font-medium text-opacity-80   ">Who We Are?</motion.div>
      <motion.div
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} className=" md:w-[70%] text-white text-opacity-50 pt-5 font-light ">
        <span className="text-[#4CE6A6]  font-semibold">DevBranch</span> is a dynamic team of creative student developers and designers from <span className=" font-semibold "> IIT Guwahati</span>, committed to delivering scalable, production-level digital solutions. We specialize in helping startups turn their ideas into real-world products, crafted with precision and built for growth. From App development to AI solutions, our work empowers businesses to thrive in the digital age.
      </motion.div>
      <motion.div 
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="  border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full border-opacity-50 my-8" ></motion.div>
      <motion.div
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
      className=" text-xl md:text-4xl text-white font-normal text-opacity-80  ">Our Mission</motion.div>
     <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} className=" md:w-[70%] text-white text-opacity-50 pt-5 font-light ">
        To build technology that empowers businesses and inmotion.dividuals to reach their full potential. Through seamless collaboration and innovation, we create
        solutions that inspire and make a lasting impact.
      </motion.div>
      <motion.div 
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="  border-t  translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full border-opacity-50 my-8" ></motion.div>
      <motion.div
       initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
      className=" text-xl md:text-4xl text-white font-normal text-opacity-80  ">Our Values</motion.div>
       <motion.div
        initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="mb-10">
        <AboutSubTile data={values} />
        <motion.div 
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full border-opacity-50 my-8 " ></motion.div>
        <motion.div
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
        className=" text-xl md:text-4xl text-white font-medium text-opacity-80   ">Our Process</motion.div>
        <motion.div
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} className=" md:w-[70%] text-white text-opacity-50 pt-5 font-light ">
          At DevBranch, we follow a structured approach to ensure the highest quality of delivery.
        </motion.div>
        <AboutSubTile data={process} />
        <motion.div 
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full border-opacity-50 my-8 " ></motion.div>
        <motion.div
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
        className=" text-xl md:text-4xl text-white font-medium text-opacity-80   ">Why Partner with US?</motion.div>
        <motion.div
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} className=" md:w-[70%] text-white text-opacity-50 pt-5 font-light ">
          At DevBranch, you’re teaming up with passionate minds from IIT Guwahati, one of India’s top institutions. We’re here to make sure your project gets
          the best tech expertise possible. Here’s what makes us stand out:
        </motion.div>
        <AboutSubTile data={partnerWith} />
        <motion.div 
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
       className="border-t translate-y-[0.5px] border-white border-dashed border-spacing-2 w-full border-opacity-50 my-8 " ></motion.div>
        <Letsconnect />
        <Footer />
      </motion.div>
    </motion.div>
  );
}

export default AboutComp;

function AboutSubTile({ data }) {
  return (
    <motion.div
     initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      {data.map((value, index) => (
        <motion.div
         initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
        className="flex mt-4 gap-4">
          <motion.div
           initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
          className="flex flex-col mb-2">
            <motion.img
               initial={{ opacity: 0, scale: 0.8 }} // Start invisible and scaled down
               animate={{ opacity: 1, scale: 0 }} // Animate to fully visible and normal size
               whileInView={{ scale: 1 }} // Scale up to 1.1 when it comes into view
               exit={{ scale: 0.8 }} // Optional: Scale down when exiting
               transition={{ duration: 0.8, ease: "easeInOut" }} // Transition for scaling
               viewport={{ once: true }} // Trigger animation only once on view
                src={value.iconPath} alt={value.title} 
                className="w-5 h-5 mr-4 mb-4" />
            <motion.div
             initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
            className="h-full md:ml-[10px] ml-[8px] md:-translate-x-[1px] border-l-[1px] border-dotted border-white border-opacity-50"></motion.div>
          </motion.div>
          <motion.div
           initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
          >
            <motion.div
             initial={{ opacity: 0, y: 50 }}
             whileInView={{ opacity: 1, y: 0 }}
             transition={{ duration: 0.8, ease: "easeInOut" }}
             viewport={{ once: true }}
              className=" text-white font-normal text-opacity-80  ">{value.title}</motion.div>
            <motion.div
             initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }} className=" md:w-[70%] text-white text-opacity-50 mt-2 font-light ">{value.description}</motion.div>
          </motion.div>
        </motion.div>
      ))}
    </motion.div>
  );
}
