import React, { useState } from 'react'
import arrowRight from '../../assets/icons-svg/arrow-right.svg'
import { Link, useNavigate } from 'react-router-dom'
function Varient1({link,content}) {
    const [hovered, setHovered] = useState(false)
    const navigate =useNavigate();
    const handleClick = (e) => {
      e.preventDefault() // Prevent immediate navigation
      setHovered(true) // Trigger hover effect
      setTimeout(() => {
        navigate(link) // Navigate after 300ms
      }, 300) // Delay for 300ms (adjust the delay as needed)
  }

  return (
    <div className="   notapcolor text-opacity-60 gap-1 transition-all hover:border-white hover:border-opacity-[0] hover:bg-white hover:bg-opacity-10 hover:text-[#2BE87D] items-center justify-center font-light text-sm cursor-pointer text-white  p-3 px-8 rounded-xl whitespace-nowrap flex border-white border-[0.5px] border-opacity-50 "
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    onClick={handleClick} >
    {content}
    <div className={`"w-2 h-[11px] ml-1 transition-all ${hovered&&" translate-x-1"}`}>
    <svg width="15" height="12" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7806 8.03104L11.0306 14.781C10.8899 14.9218 10.699 15.0008 10.5 15.0008C10.301 15.0008 10.1101 14.9218 9.96937 14.781C9.82864 14.6403 9.74958 14.4494 9.74958 14.2504C9.74958 14.0514 9.82864 13.8605 9.96937 13.7198L15.4397 8.25042H0.75C0.551088 8.25042 0.360322 8.1714 0.21967 8.03075C0.0790178 7.8901 0 7.69933 0 7.50042C0 7.3015 0.0790178 7.11074 0.21967 6.97009C0.360322 6.82943 0.551088 6.75042 0.75 6.75042H15.4397L9.96937 1.28104C9.82864 1.14031 9.74958 0.94944 9.74958 0.750417C9.74958 0.551394 9.82864 0.360523 9.96937 0.219792C10.1101 0.0790615 10.301 0 10.5 0C10.699 0 10.8899 0.0790615 11.0306 0.219792L17.7806 6.96979C17.8504 7.03945 17.9057 7.12216 17.9434 7.21321C17.9812 7.30426 18.0006 7.40186 18.0006 7.50042C18.0006 7.59898 17.9812 7.69657 17.9434 7.78762C17.9057 7.87867 17.8504 7.96139 17.7806 8.03104Z" fill={`${hovered?"#4CE6A6":"#FFFFFF90"}`}/>
</svg>

    </div>
  </div>
  )
}

export default Varient1
