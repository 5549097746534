import React from 'react'
import Nav from '../components/Nav'
import AboutComp from '../components/About'

function About() {
  return (
    <div className="flex h-screen w-full">
    {/* Nav should take 20% of the width and remain fixed */}
    <div className="md:w-[20%]">
      <Nav />
    </div>
    
    {/* The Hero content should take up the remaining 80% and be scrollable */}
    <div className="md:w-[80%]  md:px-16 md:pt-32 py-20 p-5">
     <AboutComp/>
    </div>
  </div>
  )
}

export default About
