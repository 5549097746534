import React, { useState } from "react";
import arrowRight from "../assets/icons-svg/arrow-right.svg";
import { Link } from "react-router-dom";
function Project({ project }) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      to={`/projects/${project.name.toLowerCase()}`}
      className="md:w-[45%] w-full  flex cursor-pointer  flex-col"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="overflow-hidden rounded-[20px] transform transition-transform duration-500 ease-in-out aspect-square">
        <img
          src={project.image}
          alt="project"
          className={` ${
            hovered && "scale-105 grayscale-0"
          } object-cover  grayscale transition-transform duration-500 ease-in-out rounded-[20px] w-full h-full `}
        />
      </div>

      <div className=" flex gap-2 items-center justify-between my-5 mt-7 animate-slideUp ">
        <div>
          <div
            className={`${
              hovered && "translate-x-2"
            }  transition-transform duration-300  uppercase textg text-xs `}
          >
            {project.name}
          </div>
          <p
            className={`${
              hovered && "translate-x-2"
            }  transition-transform duration-300  text-white font-light`}
          >
            {project.description}
          </p>
        </div>
        <div
          className="transition-transform duration-300 group-hover:translate-x-2"
        >
          <img
            src={arrowRight}
            alt="arrow-right"
            className={`w-3 h-3 transition-all mr-5 ${
              hovered && "translate-x-2"
            }`}
          />
        </div>
      </div>
    </div>
  );
}

export default Project;
