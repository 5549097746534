import React from "react";
import Project from "./Project";
import tipstaCover from '../assets/tipsta_cover.jpg'
import collampusCover from '../assets/collampus_cover.jpg'

function ProjectHome() {
  const homeProjects = [
    {
      title: "Tipsta",
      subTitle: "Discover & Share Experiences",
      img: tipstaCover,
    },
    {
      title: "Collampus",
      subTitle: "Connect, Collaborate, Innovate",
      img: collampusCover,
    },
  ];
  return (
    <div className=" flex flex-wrap gap-8">
      {homeProjects.map((data, index) => (
        <Project project={{ image: data.img, name: data.title, description: data.subTitle, link: "/" }} />
      ))}
    </div>
  );
}

export default ProjectHome;
