import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import rightArrow from '../assets/icons-svg/arrow-right.svg'

function Letsconnect() {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault() // Prevent immediate navigation
    setHovered(true) // Trigger hover effect
    setTimeout(() => {
      navigate('/contact') // Navigate after 300ms
    }, 300) // Delay for 300ms (adjust the delay as needed)
  }
  return (
    <div to={'/contact'} className={` ${hovered&&" text-opacity-[0.12]"} transition-all  relative flex-col text-center flex my-10 bg-gradient-to-l from-black via-transparent to-black  overflow-hidden text-white font-bold text-opacity-50 justify-center cursor-pointer text-[8vw]`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      Let's Connect!
     <div className={`${!hovered&&"hidden"} absolute flex   gap-12 justify-center`}>
      {[1,2,3,4,5,12,12,12,12,12,12,2,3,4,5,12,12,12,12,12,,2,3,4,5,12,12,12,12,12,,2,3,4,5,12,12,12,12,12,,2,3,4,5,12,12,12,12,12,,2,3,4,5,12,12,12,12,12].map((data,index)=>
        <img className='-z-10 h-12 animate-scrollright' src={rightArrow}/>
      )}
     </div>
    </div>
  )
}

export default Letsconnect
