// src/App.js
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashBoard from "./pages/DashBord";
import Stack from "./pages/Stack";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { useEffect } from "react";
import ProjDetails from "./pages/ProjDetails";

function App() {
  const location = useLocation(); // Get current location

  useEffect(() => {
    // Scroll to top when the pathname changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<DashBoard />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/stack" element={<Stack />} />
      <Route path="/projects/:name" element={<ProjDetails/>} />

    </Routes>
  );
}

function AppWithWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWithWrapper;
