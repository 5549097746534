import React from 'react'

function Footer() {
return (
    <div className=' flex justify-between text-opacity-75 bg-white bg-opacity-10 py-4 px-5 rounded-xl text-white text-sm mb-8 '>
       DevBranch © Copyright 2024. All rights Reserved.
    </div>
);
}

export default Footer
